@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/resets" as *;

.header {
  display: flex;
  width: 100%;
  background-color: white;
  margin: 0 auto;
  @include tablet {
    display: flex;
    width: 100%;
    background-color: white;
    margin: 0 auto;
    box-shadow: 0px 5px 12px #d6d5d5;
    position: sticky;
    top: 0;
    z-index: 10000;
  }
}
