@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/resets" as *;

.footer {
  display: flex;
  justify-content: space-evenly;
  background-color: $secondary-colour;

  &__icon {
    padding: 1rem 1rem 1rem 1rem;
    height: 4rem;
  }
}
