@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/resets" as *;

.carousel {
  &__img {
    height: 35rem;
    margin: auto;
    display: block;
  }
}
