@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/resets" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.NavLinks {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 3.3rem;
  height: 14rem;
  justify-content: space-around;

  @include tablet {
    position: relative;
    flex-direction: row;
    justify-content: space-between;

    height: 2rem;
    top: 0;
    width: 70%;
  }

  &__link {
    text-decoration: none;
    font-weight: bold;
    color: $primary-coulour-alternate;
    position: relative;
    transition: color 0.3s ease;
    padding: 0.3rem;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

    @include tablet {
      box-shadow: none;
    }

    &.active {
      color: $primary-coulour-alternate;
      padding-bottom: 0.3rem;
      border-bottom: 2px $primary-coulour-alternate solid;
      transition: none;
      transform: none;
      &::after {
        transition: none;
      }
      &:hover {
        &::after {
          width: 0;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $primary-coulour-alternate;
      transform-origin: left;
      transition: width 0.3s ease;
    }

    &:hover {
      color: $primary-coulour-alternate;

      &::after {
        width: 100%;
      }
    }
  }

  &__cv {
    text-decoration: none;
    color: white;
    background: linear-gradient(
      180deg,
      $primary-coulour-alternate,
      $primary-coulour-alternate 2rem
    );
    width: 8rem;
    border-radius: 0.25rem;
    padding: 0.3rem;
    display: flex;
    justify-content: space-between;
    transition: transform 0.2s ease;
  }
  &__cv:hover {
    transform: scale(1.04);
    color: black;
  }
  &__download {
    width: 1.5rem;
  }
}
