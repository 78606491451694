@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/resets" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.techstack {
  background: white;
  &__title-container {
    width: 100%;
    padding: 1rem 0rem 1rem 1rem;
  }

  &__title {
    font-size: 1.5rem;
    color: white;

    &--white {
      color: white;
    }
  }

  &__icon-container {
    max-width: 1280px;
    margin: 0 auto;
  }

  &__icon-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
  }

  &__icon {
    height: 3rem;
    padding: 0rem 0.3rem 0.3rem 0.3rem;
  }

  &__link {
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
    background: rgba(248, 249, 249, 0.66);
    border-radius: 0.5rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.4px);
    -webkit-backdrop-filter: blur(9.4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0.5rem 0.5rem;

    transition: transform 0.2s ease;
  }

  &__link:hover {
    background-color: rgb(252, 252, 252);
    transform: scale(1.05);
    .techstack__caption {
      color: black;
    }
  }

  &__figures {
    text-decoration: none;
    color: black;
  }

  &__caption {
    font-size: 0.6rem;
    color: white;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 1.5s fadeInUp;
}
