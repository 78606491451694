$tablet-breakpoint: 786px;
$desktop-breakpoint: 1280px;

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}
