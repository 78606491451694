@use "./typography" as *;
@use "./variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: ShareTechMono;
}

body {
  margin: 0;
  padding: 0;
  background-color: $primary-colour-light;
}
