@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/resets" as *;

.mobile-nav {
  display: flex;
  height: 3.3rem;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
  margin: 2rem 1rem;
  position: fixed;
  z-index: 110;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

  @include tablet {
    display: none;
  }

  &__logo {
    font-size: 1.5rem;
    margin: 0;
    font-style: italic;
    color: $primary-coulour-alternate;
  }

  &__menu {
    height: 1.5rem;
    width: 2rem;
    color: $primary-coulour-alternate;
  }
  &__menu-close {
    height: 1.5rem;
    width: 2rem;
    color: $primary-coulour-alternate;
  }
}
